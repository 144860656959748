import React, { useEffect, useState } from "react";
import { useRouter } from "../../../common/useRouter";
import { useTranslation } from "react-i18next";
import {
	Box,
	Badge,
	Flex,
	Text,
	Link,
	Button
} from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { FaShippingFast, FaBoxOpen } from "react-icons/fa";
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../../common/api_routes";
import CustomTable from "../../../components/custom-table";
import { WebUrl } from "../../../routes";
import { Role, InvoiceStatus, ApiKey } from "../../../common/constant";
import { showCustomDialog } from "../../../application/action/app_action";
import { numberWithCurrencyFormat, renderDiamondTypeBadge, getLabCertificateTrackUrl, stringIsNullOrEmpty, renderInvoiceStatus } from "../../../common/util";
import moment from "moment";
import InvoiceDiamondDetail from "../../../components/invoice-diamond-detail";
import ApiEngine from "../../../common/api-engine";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
	var _dispatch = useDispatch();
	var _router = useRouter();
	const { t } = useTranslation();
	const _USER_DATA = useSelector((state) => state.authState.userData);
	const [apiUrl, setApiUrl] = useState(`${Routes._INVENTORY_INVOICE}`);
	const [checkAll, setCheckAll] = useState(false);
	const [allDiamonds, setAllDiamonds] = useState([]);
	const [selectedDiamonds, setSelectedDiamonds] = useState([]);

	const _COLUMNS = [
		{
			id: 'checkbox',
			headerWidth: '1%',
			customHeader: (
				<div className="checkbox checkbox-css checkbox-inline inbox-checkbox">
					<input id="checkboxSelectAll" type="checkbox" onChange={() => setCheckAll(!checkAll)} checked={checkAll} />
					<label htmlFor="checkboxSelectAll"></label>
				</div>
			),
			Cell: ({ row }) => {
				return (
					<div className="checkbox checkbox-css checkbox-inline inbox-checkbox">
						<input
							id={`checkbox${row.id}`}
							type="checkbox"
							onChange={() => updateSelectedDiamonds(row.original)}
							checked={selectedDiamonds.find(t => t.id == row.original.id)}
						/>
						<label htmlFor={`checkbox${row.id}`}></label>
					</div>
				)
			},
			width: 30,
			disableSortBy: true,
		},
		{
			Header: "INVOICE_NO",
			accessor: "invoiceNo",
			headerClassName: "col-align-center",
			Cell: ({ row }) => {
				return <Flex flexDir={"column"}>
					<Text textAlign={'center'} fontWeight={'bold'} fontSize={'14px'}>{row.original.invoiceNo}</Text>
					{
						row.original.status == InvoiceStatus._PENDING ?
							<Text textAlign={'center'} color="secondaryGray.500" fontWeight={500} fontSize={'12px'}>{moment(row.original.createdAt).format('YYYY-MM-DD h:mm:ss A')}</Text> :
							<Text textAlign={'center'} color="secondaryGray.500" fontWeight={500} fontSize={'12px'}>{moment(row.original.updatedAt).format('YYYY-MM-DD h:mm:ss A')}</Text>
					}
				</Flex>
			}
		},
		{
			Header: "TOTAL_PIECE",
			accessor: "quantity",
			headerClassName: "col-align-center",
			Cell: ({ row }) => {
				return <Flex flexDir={"column"}>
					<Text textAlign={'center'}>{row.original.quantity}</Text>
				</Flex>
			}
		},
		{
			Header: "TOTAL_CARAT",
			accessor: "totalCarat",
			headerClassName: "col-align-center",
			Cell: ({ row }) => {
				return <Flex flexDir={"column"}>
					<Text textAlign={'center'}>{row.original.totalCarat}</Text>
				</Flex>
			}
		},
		{
			Header: "TOTAL_PRICE",
			accessor: "totalPrice",
			headerClassName: "col-align-center",
			Cell: ({ row }) => {
				return <Flex flexDir="column" alignItems={'center'}>
					<Text textAlign={'center'} fontWeight={700} fontSize={'16px'}>${numberWithCurrencyFormat(row.original.totalPrice)}</Text>
					<Text textAlign={'center'} color="secondaryGray.500" fontWeight={500} fontSize={'12px'}>$/CT {numberWithCurrencyFormat(parseFloat(row.original.totalPricePerCarat))}</Text>
				</Flex>
			}
		},
		{
			Header: "STATUS",
			headerClassName: "col-align-center",
			Cell: ({ row }) => {
				return <Flex flexDir="column" alignItems={'center'}>
					{renderInvoiceStatus(row.original.status)}
				</Flex>
			}
		},
		{
			Header: "COURIER",
			headerClassName: "col-align-center",
			Cell: ({ row }) => {
				return stringIsNullOrEmpty(row.original.logisticName) ?
					<Text textAlign={'center'}>{"-"}</Text> :
					<Link href={row.original.logisticTrackUrl} isExternal>
						{row.original.logisticName} <ExternalLinkIcon mx='2px' />
					</Link>
			}
		},
	];

	/// <summary>
	/// Author: Saitama
	/// </summary> 
	useEffect(() => {
		let diamonds = [];

		if (checkAll && allDiamonds.length > 0) {
			diamonds = allDiamonds.map(selected => selected);
		}

		setSelectedDiamonds(diamonds);
	}, [checkAll]);

	/// <summary>
	/// Author: Saitama
	/// </summary> 
	function updateSelectedDiamonds(diamond) {
		if (selectedDiamonds.filter(i => i.id == diamond.id).length) {
			setSelectedDiamonds(selectedDiamonds.filter(i => i.id != diamond.id));
		}
		else {
			setSelectedDiamonds([...selectedDiamonds, diamond]);
		}
	}

	/// <summary>
	/// Author: Saitama
	/// </summary> 
	async function updateShippedStatus() {
		try {
			var responseJson = await ApiEngine.put(Routes._INVENTORY_INVOICE_SHIPPED, { invoiceIds: selectedDiamonds.map(i => i.id).join(',') });

			if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

			resetData(true);
		}
		catch (err) {
			_dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
		}
	}

	/// <summary>
	/// Author: Saitama
	/// </summary> 
	async function updateReceivedStatus() {
		try {
			var responseJson = await ApiEngine.put(Routes._INVENTORY_INVOICE_RECEIVED, { invoiceIds: selectedDiamonds.map(i => i.id).join(',') });

			if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

			resetData(true);
		}
		catch (err) {
			_dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
		}
	}

	/// <summary>
	/// Author: Saitama
	/// </summary> 
	function resetData(triggerApiCall = false) {
		setSelectedDiamonds([]);
		setCheckAll(false);

		if (triggerApiCall) {
			setApiUrl(`${Routes._INVENTORY_INVOICE}?v=${new Date()}`);
		}
	}

	return (
		<Box pt={{ base: "80px", md: "60px", xl: "60px" }}>
			<Card
				justifyContent='center'
				align='center'
				direction='column'
				w='100%'
				mb='0px'>
				<Flex justifyContent={'flex-end'} gap={'7.5px'}>
					<Button
						isDisabled={selectedDiamonds.length <= 0}
						colorScheme={'blue'}
						fontSize="sm"
						fontWeight="500"
						leftIcon={<FaBoxOpen />}
						onClick={() => {
							if (selectedDiamonds.findIndex(i => i.status != InvoiceStatus._SHIPPED) > -1) {
								_dispatch(
									showCustomDialog({
										success: false,
										content: 'MAKE_SURE_ALL_SELECTED_INVOICE_IS_SHIPPED_STATUS',
									})
								);
							}
							else {
								_dispatch(
									showCustomDialog({
										title: "ARE_YOU_SURE",
										content: t("ARE_YOU_SURE_YOU_WANT_TO_UPDATE_RECEIVED", { invoice: selectedDiamonds.map(item => item.invoiceNo).join(', ') }),
										onConfirm: () => { updateReceivedStatus(); },
										onCancel: () => { }
									})
								);
							}
						}}
					>
						{t('RECEIVED')}
					</Button>
					<Button
						isDisabled={selectedDiamonds.length <= 0}
						colorScheme={'blue'}
						fontSize="sm"
						fontWeight="500"
						leftIcon={<FaShippingFast />}
						onClick={() => {
							if (selectedDiamonds.findIndex(i => stringIsNullOrEmpty(i.logisticTrackUrl)) > -1) {
								_dispatch(
									showCustomDialog({
										success: false,
										content: 'INVOICE_LACK_OF_TRACKING_INFO',
									})
								);
							}
							else if (selectedDiamonds.findIndex(i => i.status == InvoiceStatus._RECEIVED) > -1) {
								_dispatch(
									showCustomDialog({
										success: false,
										content: 'ONE_OR_MORE_ITEM_ALREADY_RECEIVED',
									})
								);
							}
							else {
								_dispatch(
									showCustomDialog({
										title: "ARE_YOU_SURE",
										content: t("ARE_YOU_SURE_YOU_WANT_TO_UPDATE_SHIPPED", { invoice: selectedDiamonds.map(item => item.invoiceNo).join(', ') }),
										onConfirm: () => { updateShippedStatus(); },
										onCancel: () => { }
									})
								);
							}
						}}
					>
						{t('SHIPPED')}
					</Button>
				</Flex>
				<CustomTable
					columnsData={_COLUMNS}
					apiUrl={apiUrl}
					customFetchAction={(responseJson) => setAllDiamonds(responseJson[ApiKey._API_DATA_KEY])}
					RowAccordionContent={InvoiceDiamondDetail}
				/>
			</Card>
		</Box>
	)
}